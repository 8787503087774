import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="HOW TO PLAY" isOpen={isOpen} handleClose={handleClose}>
      <p className="font-poppins font-light text-sm text-black py-2 dark:text-white">
      Welcome to <span className="font-bold">PHOODLE</span>! Test your culinary expertise by guessing a food-related term, from appliances to famous chefs and more. Each word is five letters, and you have six tries to solve the puzzle.
      </p>

      <p className="font-poppins font-light text-sm text-black pb-2 pt-2 dark:text-white">
      After each guess, the color of the tiles will change to show how close your guess was to the word.
      </p>

      <p className="font-poppins font-light text-sm text-black pb-2 pt-2 dark:text-white">
      A new PHOODLE will be available each day!
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="T"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="C" />
        <Cell value="O" />
        <Cell value="S" />
      </div>
      <p className="font-poppins whitespace-nowrap text-xs sm:text-sm font-light text-black dark:text-white">
        The letter T is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="F" />
        <Cell value="I" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="B"
          status="present"
        />
        <Cell value="E" />
        <Cell value="R" />
      </div>
      <p className="font-poppins font-light whitespace-nowrap text-xs sm:text-sm text-black dark:text-white">
        The letter B is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="A" />
        <Cell value="K" />
        <Cell isRevealing={true} isCompleted={true} value="E" status="absent" />
        <Cell value="D" />
      </div>
      <p className="font-poppins font-light whitespace-nowrap text-xs sm:text-sm text-black dark:text-white">
        The letter E is not in the word in any spot.
      </p>
    </BaseModal>
  )
}
