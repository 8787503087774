import { GameStats } from '../../lib/localStorage'
import { solution } from '../../lib/words'
import { BaseModal } from './BaseModal'
import phoodleQR from '../../images/FestivalQR.png'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  description: string
}

export const FactModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
  description
}: Props) => {
  
  if (!isGameLost && !isGameWon) {
    return (
      <BaseModal
        title="SOLUTION"
        isOpen={isOpen}
        handleClose={handleClose}
        isFact={true}
        className={true}
      >
        <div className='font-poppins py-3 font-light dark:text-white'>
          <p>After playing the game a Phoodle Fact about the word will appear here</p>
        </div>
      </BaseModal>
    )
  }
  function refreshPage(): void {
    window.location.reload();
  }

  return (
    <BaseModal
      title={solution.toUpperCase()}
      isOpen={isOpen}
      handleClose={handleClose}
      isFact={true}
    > 
      <div className='divide-y divide-solid divide-buttonGreen'>
        <div className='pb-4'>
          <h3 className='font-bold dark:text-white'>PHOODLE FACT</h3>
          <div className='dark:text-white py-3 leading-5 font-light font-poppins'>
            <span>{description}</span>
          </div>
        </div>
        <div>
          <div className="flex flex-col justify-center items-center mt-2 font-poppins dark:text-white">
            <h3 className='font-light'>
              Snap this QR code to follow @phoodlegame <br></br> and win some Phoodle swag!
            </h3>
            <img
              src={phoodleQR}
              alt={'Phoodle QR Code'}
              className='h-20 w-20 justify-center object-contain'
            />
            <em className='text-buttonGreen underline cursor-pointer' onClick={refreshPage}>New Game</em>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
