import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import {
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'
import {
  MailIcon
} from '@heroicons/react/outline'
import { ReactComponent as Twitter } from "../../images/twitter.svg"
import { ReactComponent as Instagram } from "../../images/instagram.svg" 

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  
  return (
    <BaseModal title="SETTINGS" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2 divide-y">
        <SettingsToggle
          settingName="Dark Mode"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="High Contrast Mode"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
        <div className="flex font-poppins font-light justify-between gap-4 py-3">
          <div className="text-black dark:text-white mt-2 text-left">
            <span>Feedback</span>
          </div>
          <a href="mailto:phoodlegame@gmail.com" className='outline-none'>
            <MailIcon 
              className='h-6 mr-3 mt-1 stroke-black dark:stroke-white'
            />
          </a>
        </div>
        <div className="flex font-poppins font-light justify-between gap-4 py-3">
          <div className="text-black dark:text-white mt-2 text-left">
            <span>Community</span>
          </div>
          <div className='flex'>
            <a href="https://twitter.com/phoodlegame" target="_blank" rel="noopener noreferrer" className='outline-none'>
              <Twitter 
                className='h-6 mt-1 mr-1 stroke-black fill-white dark:stroke-white dark:fill-background'
              />
            </a>
            <a href="https://instagram.com/phoodlegame" target="_blank" rel="noopener noreferrer" className='outline-none'>
              <Instagram
                className='h-6 mt-1 ml-1 stroke-black dark:fill-white'
              />
            </a>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
