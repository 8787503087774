export const WORDS = 
[
  {
    "Word": "Apple",
    "Description": "One apple gives you more energy than a cup of coffee–sorry, coffee lovers",
    "": "",
    "__1": ""
  },
  {
    "Word": "Apron",
    "Description": "Originally called a napron, meaning \"small tablecloth\" in French, but was so often mispronounced it became apron",
    "": "",
    "__1": ""
  },
  {
    "Word": "Bacon",
    "Description": "About 70% of the bacon consumed in the U.S. is eaten at breakfast",
    "": "",
    "__1": ""
  },
  {
    "Word": "Bagel",
    "Description": "Bagels and pretzels are the only bread doughs that are traditionally boiled before being baked",
    "": "",
    "__1": ""
  },
  {
    "Word": "Basil",
    "Description": "In Italy, basil is a token of love",
    "": "",
    "__1": ""
  },
  {
    "Word": "Berry",
    "Description": "A banana is technically a berry–and a strawberry is not",
    "": "",
    "__1": ""
  },
  {
    "Word": "Bread",
    "Description": "Wonder Bread was the first pre-sliced bread to be sold nationally",
    "": "",
    "__1": ""
  },
  {
    "Word": "Broth",
    "Description": "A savory liquid made from strained meat, fish, vegetables, and aromatics",
    "": "",
    "__1": ""
  },
  {
    "Word": "Candy",
    "Description": "Surprisingly, an American dentist invented the cotton candy machine",
    "": "",
    "__1": ""
  },
  {
    "Word": "Caper",
    "Description": "The flower bud of a bush that is picked, sun-dried, and then typically pickled or salted",
    "": "",
    "__1": ""
  },
  {
    "Word": "Chips",
    "Description": "Potato chips are American’s favorite snack food",
    "": "",
    "__1": ""
  },
  {
    "Word": "Chive",
    "Description": "An herb with a mild onion-like taste, typically used as a garnish",
    "": "",
    "__1": ""
  },
  {
    "Word": "Cider",
    "Description": "It takes 36 apples to make at least one gallon of cider",
    "": "",
    "__1": ""
  },
  {
    "Word": "Clove",
    "Description": "A pungent spice used in small amounts to give flavor to hot beverages like mulled wine",
    "": "",
    "__1": ""
  },
  {
    "Word": "Cocoa",
    "Description": "Cocoa is thought to be an aphrodisiac",
    "": "",
    "__1": ""
  },
  {
    "Word": "Cream",
    "Description": "Often used as an ingredient in sauces, soups, puddings, custards, and cakes",
    "": "",
    "__1": ""
  },
  {
    "Word": "Crepe",
    "Description": "A thin pancake made even better when filled with Nutella",
    "": "",
    "__1": ""
  },
  {
    "Word": "Crumb",
    "Description": "A morsel of bread, cookie, or cracker, loved especially by ants",
    "": "",
    "__1": ""
  },
  {
    "Word": "Crust",
    "Description": "Thin pizza crust is still the most popular",
    "": "",
    "__1": ""
  },
  {
    "Word": "Cumin",
    "Description": "Long ago, it was believed that cumin kept both chickens and lovers from running away!",
    "": "",
    "__1": ""
  },
  {
    "Word": "Curry",
    "Description": "A term for sauce-based dishes with curry sauce or curry powder as the base",
    "": "",
    "__1": ""
  },
  {
    "Word": "Dairy",
    "Description": "Contrary to popular belief, eggs are not a dairy product",
    "": "",
    "__1": ""
  },
  {
    "Word": "Dates",
    "Description": "Very few people are allergic to this fruit",
    "": "",
    "__1": ""
  },
  {
    "Word": "Donut",
    "Description": "New England has the most donut shops per person",
    "": "",
    "__1": ""
  },
  {
    "Word": "Dough",
    "Description": "In the United States, \"elephant ears\" is a common name for fried dough",
    "": "",
    "__1": ""
  },
  {
    "Word": "Drink",
    "Description": "Loud music can encourage people to drink more, and faster",
    "": "",
    "__1": ""
  },
  {
    "Word": "Float",
    "Description": "A popular soda fountain treat is the root beer float",
    "": "",
    "__1": ""
  },
  {
    "Word": "Flour",
    "Description": "Buckwheat flour is used as an ingredient in many pancakes in the United States",
    "": "",
    "__1": ""
  },
  {
    "Word": "Fries",
    "Description": "French fries are not French but originated in Belgium",
    "": "",
    "__1": ""
  },
  {
    "Word": "Fruit",
    "Description": "Tomatoes are a fruit, and one of the most popular types, at that",
    "": "",
    "__1": ""
  },
  {
    "Word": "Fudge",
    "Description": "Refrigerating fudge can extend its shelf life by two to three weeks",
    "": "",
    "__1": ""
  },
  {
    "Word": "Grain",
    "Description": "Oats are one of the healthiest grains",
    "": "",
    "__1": ""
  },
  {
    "Word": "Grape",
    "Description": "A grape will explode if you microwave it (don't try this at home)",
    "": "",
    "__1": ""
  },
  {
    "Word": "Gravy",
    "Description": "A sauce best served in a boat",
    "": "",
    "__1": ""
  },
  {
    "Word": "Grill",
    "Description": "July 4th Is the most popular day for grilling",
    "": "",
    "__1": ""
  },
  {
    "Word": "Gumbo",
    "Description": "A Creole stew containing okra, tomatoes, onions, and meats or shellfish",
    "": "",
    "__1": ""
  },
  {
    "Word": "Honey",
    "Description": "Honey will never, ever go bad",
    "": "",
    "__1": ""
  },
  {
    "Word": "Icing",
    "Description": "It is often confused with frosting",
    "": "",
    "__1": ""
  },
  {
    "Word": "Jello",
    "Description": "Most Americans have a package of Jell-O in their cupboards",
    "": "",
    "__1": ""
  },
  {
    "Word": "Jelly",
    "Description": "Jelly is made with fruit juice, whereas jam is made with fruit",
    "": "",
    "__1": ""
  },
  {
    "Word": "Juice",
    "Description": "The natural liquid extracted from fruits, vegetables, meats, and poultry",
    "": "",
    "__1": ""
  },
  {
    "Word": "Julep",
    "Description": "The traditional drink of the Kentucky Derby, always made with fresh mint, sugar, and whiskey",
    "": "",
    "__1": ""
  },
  {
    "Word": "Knead",
    "Description": "To fold, push, and turn dough with the heels of your hands to produce a smooth, elastic texture",
    "": "",
    "__1": ""
  },
  {
    "Word": "Knife",
    "Description": "Wooden cutting boards are the best for kitchen knives",
    "": "",
    "__1": ""
  },
  {
    "Word": "Lager",
    "Description": "Beer is said to be the 3rd most-consumed beverage in the world, behind water and tea",
    "": "",
    "__1": ""
  },
  {
    "Word": "Leafy",
    "Description": "Vegetables whose leaves we eat, like bok choy, cabbage, chicory, and lettuce",
    "": "",
    "__1": ""
  },
  {
    "Word": "Leeks",
    "Description": "Root vegetables related to onions, and a staple in French cuisine",
    "": "",
    "__1": ""
  },
  {
    "Word": "Lemon",
    "Description": "A lemon will float, but a lime will not",
    "": "",
    "__1": ""
  },
  {
    "Word": "Limes",
    "Description": "A green fruit rich in potassium",
    "": "",
    "__1": ""
  },
  {
    "Word": "Lunch",
    "Description": "The average time taken to eat lunch is roughly 15 minutes, and often happens while at your desk",
    "": "",
    "__1": ""
  },
  {
    "Word": "Mango",
    "Description": "To speed up ripening, place mangos in a paper bag at room temperature",
    "": "",
    "__1": ""
  },
  {
    "Word": "Melon",
    "Description": "Americans eat more watermelon than any other type of melon",
    "": "",
    "__1": ""
  },
  {
    "Word": "Mince",
    "Description": "To cut food into very fine pieces, smaller than a chop or dice",
    "": "",
    "__1": ""
  },
  {
    "Word": "Mocha",
    "Description": "A flavoring that combines coffee and chocolate",
    "": "",
    "__1": ""
  },
  {
    "Word": "Nacho",
    "Description": "A tortilla chip typically topped with cheese, and often a range of other savory toppings",
    "": "",
    "__1": ""
  },
  {
    "Word": "Olive",
    "Description": "Kalamata olives are best for cooking and for adding to salads",
    "": "",
    "__1": ""
  },
  {
    "Word": "Onion",
    "Description": "“An onion can make people cry, but there has never been a vegetable invented to make them laugh.” – Will Rogers",
    "": "",
    "__1": ""
  },
  {
    "Word": "Panko",
    "Description": "Flaky dried breadcrumbs adopted from Japanese cooking",
    "": "",
    "__1": ""
  },
  {
    "Word": "Pasta",
    "Description": "Thomas Jefferson made pasta popular in the United States",
    "": "",
    "__1": ""
  },
  {
    "Word": "Peach",
    "Description": "Just like a nectarine, but with fuzz",
    "": "",
    "__1": ""
  },
  {
    "Word": "Pecan",
    "Description": "The state nut of Alabama is the pecan",
    "": "",
    "__1": ""
  },
  {
    "Word": "Penne",
    "Description": "The MVP (most valuable pasta) found in kitchen pantries",
    "": "",
    "__1": ""
  },
  {
    "Word": "Pesto",
    "Description": "An uncooked sauce made from garlic, basil, nuts, Parmesan cheese, and olive oil",
    "": "",
    "__1": ""
  },
  {
    "Word": "Pilaf",
    "Description": "A rice dish cooked in a meat or poultry broth",
    "": "",
    "__1": ""
  },
  {
    "Word": "Pizza",
    "Description": "Margherita pizza's red tomatoes, green basil, and white cheese represent the Italian flag",
    "": "",
    "__1": ""
  },
  {
    "Word": "Prawn",
    "Description": "Prawns are typically larger than shrimp",
    "": "",
    "__1": ""
  },
  {
    "Word": "Prune",
    "Description": "A prune is just a dried plum, but not all plums can be dried into prunes",
    "": "",
    "__1": ""
  },
  {
    "Word": "Punch",
    "Description": "Punch is said to be one of the first cocktails",
    "": "",
    "__1": ""
  },
  {
    "Word": "Puree",
    "Description": "To process or mash a food until it is smooth like apple sauce",
    "": "",
    "__1": ""
  },
  {
    "Word": "Salad",
    "Description": "To remember Cobb salad ingredients, use the mnemonic EAT COBB: eggs, avocado, tomato, chicken, onion, bacon, and blue cheese",
    "": "",
    "__1": ""
  },
  {
    "Word": "Salsa",
    "Description": "Often eaten while watching sports, a sauce made of finely chopped tomatoes, onions, chiles, and cilantro",
    "": "",
    "__1": ""
  },
  {
    "Word": "Sauce",
    "Description": "A concoction to make other foods look, smell, and taste better",
    "": "",
    "__1": ""
  },
  {
    "Word": "Scone",
    "Description": "Best eaten with jam and cream—it's been reported that Queen Elizabeth II preferred topping hers with jam first",
    "": "",
    "__1": ""
  },
  {
    "Word": "Shake",
    "Description": "The electric blender was invented in 1922 just for milkshakes",
    "": "",
    "__1": ""
  },
  {
    "Word": "Snack",
    "Description": "Lots of people say eating a snack stopped them from having a public meltdown",
    "": "",
    "__1": ""
  },
  {
    "Word": "Squid",
    "Description": "Squid have three hearts",
    "": "",
    "__1": ""
  },
  {
    "Word": "Steak",
    "Description": "Memorial Day is one of the most popular days to eat a steak",
    "": "",
    "__1": ""
  },
  {
    "Word": "Sugar",
    "Description": "Sugar does not spoil",
    "": "",
    "__1": ""
  },
  {
    "Word": "Sushi",
    "Description": "Sushi means “with rice,” not “raw fish”",
    "": "",
    "__1": ""
  },
  {
    "Word": "Syrup",
    "Description": "More than 80 percent of the world’s maple syrup comes from Canada",
    "": "",
    "__1": ""
  },
  {
    "Word": "Tacos",
    "Description": "October 4th is the national day to celebrate tacos",
    "": "",
    "__1": ""
  },
  {
    "Word": "Taffy",
    "Description": "Salt water taffy does not contain salt water from the ocean",
    "": "",
    "__1": ""
  },
  {
    "Word": "Thyme",
    "Description": "A bit of a rock star in the herb world",
    "": "",
    "__1": ""
  },
  {
    "Word": "Toast",
    "Description": "Avocado toast is well-worth the hype",
    "": "",
    "__1": ""
  },
  {
    "Word": "Trout",
    "Description": "A large fish that lives in rivers and streams",
    "": "",
    "__1": ""
  },
  {
    "Word": "Vegan",
    "Description": "A person who adheres to a plant-based diet",
    "": "",
    "__1": ""
  },
  {
    "Word": "Wafer",
    "Description": "A thin, crisp cake, candy, or cracker best eaten with ice cream",
    "": "",
    "__1": ""
  },
  {
    "Word": "Water",
    "Description": "The average person could only survive about one week without water",
    "": "",
    "__1": ""
  },
  {
    "Word": "Wheat",
    "Description": "Wheat is the most widely grown commercial crop in the world",
    "": "",
    "__1": ""
  },
  {
    "Word": "Yeast",
    "Description": "A tiny organism that’s essential in bread baking, and beer and winemaking",
    "": "",
    "__1": ""
  }
]