import {
  CogIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline'
import { ReactComponent as ChefsHat } from '../../images/Phoodle_Chefs-Hat_Icon_200.svg'
import { ReactComponent as Logo } from "../../images/Phoodle_Logo.svg"
import { ReactComponent as DarkLogo } from "../../images/Phoodle_Dark_Logo.svg"


type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  setIsFactModalOpen: (value: boolean) => void
  isDarkMode: boolean
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsSettingsModalOpen,
  setIsFactModalOpen,
  isDarkMode,
}: Props) => {

  return (
    <div className="navbar">
      <div className="navbar-content px-5 my-5">
        <div className='left-icons w-1/4 md:w-auto'>
          <div className='h-8 w-8'></div>
          <QuestionMarkCircleIcon
            className="h-8 w-8 md:h-8 md:w-8 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>
        <div className="w-1/2 md:w-80">
          {isDarkMode ?
            <DarkLogo /> :
            <Logo  />
          }
        </div>
        <div className="right-icons w-1/4 md:w-auto">
          <ChefsHat
            className="h-8 w-8 md:h-8 md:w-8 mr-2 ml-3 cursor-pointer fill-white stroke-black dark:stroke-white dark:fill-background"
            onClick={() => setIsFactModalOpen(true)}
          />
          <CogIcon
            className="h-8 w-8 md:h-8 md:w-8 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
